import React, {useState} from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import BorderedBlock from "../Core/BorderedBlock";
import {Link} from "gatsby";

const Content = styled.div`
  ${fonts.montserratRegular};
  color: ${colors.black};
  font-size: 14px;
  line-height: 23px;
  max-width: 450px;
  text-align: center;
  
  b {
    ${fonts.montserratMedium};
    color: ${colors.newBlack};
  }
  
  a {
    ${fonts.montserratBold};
    color: ${colors.green};
    text-decoration: none;
  }
`;

const PremiumFeature = () => {
  return (
    <BorderedBlock>
      <Content>
        <b>Configure the production branch and preview branches</b>
        <br />
        Select your production branch and preview branches.
        <br />
        This feature is only available in the Team Plan.
        <br />
        <br />
        <Link to={'/account'}>Upgrade to the Team Plan</Link>
      </Content>
    </BorderedBlock>
  )
};

export default PremiumFeature
