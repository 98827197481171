import React, {useState, useEffect} from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import DeleteLg from "../../resources/img/icons/close-large.svg"
import DeleteSm from "../../resources/img/icons/close-small.svg"
import Select from "react-select"
import Arrow from "../../resources/img/icons/select-arrow-green.svg"

const StyledContainer = styled.div`
  padding: 40px 30px;
  border-bottom: 1px solid ${colors.borderGrey};
  @media (min-width: ${breakpoints.md}) {
    padding: 40px 96px;
  }
`;

const OptionTitle = styled.div`
  ${fonts.montserratMedium};
  color: ${colors.black};
  font-size: 14px;
  line-height: 18px;
  width: 30%;
  padding-right: 10px;
  @media (min-width: ${breakpoints.sm}) {
    width: 20%;
  }
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: ${(props) => (props.center ? "center" : "flex-start")};
`

const StyledInput = styled.input`
  border-radius: 5px;
  border: 1px solid ${colors.greyDarker};
  width: 100%;
  height: 40px;
  background: ${colors.greyLightPanel};
  padding: 0 40px 0 17px;
  ${fonts.montserratBold};
  font-size: 14px;
  line-height: 23px;
  color: ${colors.black};
`;

const Radio = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 33px;
  box-sizing: border-box;
  margin-right: 30px;
  margin-bottom: 10px;
  :last-of-type {
    margin-bottom: 0;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: ${colors.white};
    border-radius: 50%;
    border: 1px solid ${colors.greyDarker};
    box-sizing: border-box;
  }
  :hover input ~ .checkmark {
    background-color: ${colors.white};
  }

  input:checked ~ .checkmark {
    background-color: ${colors.green};
    border: 1px solid ${colors.green};
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    border-radius: 50%;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background: ${colors.white};
    border-radius: 50%;
    border: 1px solid ${colors.white};
  }

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
  }
`;

const RadioContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    display: flex;
  }
`;

const OptionValues = styled.div`
  width: 70%;
  @media (min-width: ${breakpoints.sm}) {
    width: 80%;
  }
`;

const SelectedBranches = styled.div`
  margin-top: 34px;
  background: ${colors.greyLightPanel};
  border: 1px solid ${colors.greyDark};
  border-radius: 5px;
  width: 100%;
  padding: 7px;
`;

const BranchName = styled.div`
  ${fonts.montserratBold};
  font-size: 12px;
  line-height: 23px;
  border-radius: 5px;
  background: ${colors.greyDark};
  padding: 0 10px;
  position: relative;
  padding-right: 30px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 6px 6px 0;
`;

const InputContainer = styled.div`
  position: relative;
`;

const DeleteButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: ${(props) => (props.lg ? "0 13px" : "0 10px 0 5px")};
`;

const CustomSelect = styled(Select)`
  ${fonts.montserratMedium};
  font-size: 14px;
  line-height: 23px;
  margin-top: 34px;
`;

const SingleSelect = styled(Select)`
  ${fonts.montserratBold};
  font-size: 14px;
  line-height: 23px;
  color: ${colors.black};
`;

const DropdownIndicatorButton = styled.div`
  padding: 0 10px;
  display: ${(props) => (props.visible ? "flex" : "none")};
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const DropdownIndicator = (props) => {
  const {isFocused} = props;

  return (
    <DropdownIndicatorButton visible={isFocused}>
      <img src={Arrow} alt="Dropdown"/>
    </DropdownIndicatorButton>
  )
};

const MultiValue = (props) => {
  const {children} = props;

  const remove = (e, children, props) => {
    e.preventDefault();

    props.removeProps.onClick();
  };

  return (
    <BranchName>
      {children}
      <DeleteButton onClick={(e) => remove(e, children, props)}>
        <img src={DeleteSm} alt={`delete ${children}`}/>
      </DeleteButton>
    </BranchName>
  )
};

const singleSelectStyles = {
  control: (styles, {isSelected, isFocused}) => ({
    ...styles,
    backgroundColor: colors.greyLightPanel,
    boxShadow: "0 !important",
    minHeight: "40px",
    border: `1px solid ${colors.greyDarker} !important`,
    cursor: "pointer"
  }),
  menu: (styles) => ({
    ...styles,
    border: "1px solid " + colors.greyDarker,
    borderTop: "transparent",
    borderRadius: "none",
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    color: colors.black,
    top: "auto",
    marginTop: "0",
  }),
  input: (styles) => ({
    ...styles,
    fontFamily: "Montserrat Bold",
    width: "100%",
    "& input": {
      font: "inherit",
    },
  }),
  option: (styles, {data, isSelected}) => ({
    ...styles,
    color: isSelected ? colors.white : colors.black,
    backgroundColor: isSelected ? colors.green : colors.white,
    cursor: "pointer",
    ':hover': {
      color: isSelected ? colors.white : colors.black,
      backgroundColor: isSelected ? colors.green : "rgba(34,206,182, 0.2)",
    },
    ':active': {
      color: colors.white,
      backgroundColor: colors.green,
    },
  }),
  indicatorSeparator: (styles) => ({...styles, display: "none"}),
  indicatorsContainer: styles => ({
    ...styles,
    background: "url(" + DeleteLg + ") no-repeat",
    backgroundSize: "auto",
    backgroundPosition: "center",
    width: "40px",
    cursor: "pointer"
  }),
  dropdownIndicator: (styles) => ({...styles, display: "none"}),
}

const selectStyles = {
  control: (styles, {isSelected, isFocused}) => ({
    ...styles,
    backgroundColor: colors.greyLightPanel,
    boxShadow: "0 !important",
    minHeight: "40px",
    cursor: "pointer",
    border: `1px solid ${colors.greyDarker} !important`
  }),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    return {
      ...styles,
      cursor: "pointer",
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? colors.green
          : isFocused
            ? colors.green
            : null,
      color: isDisabled
        ? null
        : isSelected
          ? colors.white
          : isFocused
            ? colors.white
            : null,
      ':hover': {
        color: isSelected ? colors.white : colors.black,
        backgroundColor: isSelected ? colors.green : "rgba(34,206,182, 0.2)",
      },
      ':active': {
        color: colors.white,
        backgroundColor: colors.green,
      },
    }
  },
  menu: (styles) => ({
    ...styles,
    fontSize: "13px",
    border: "1px solid " + colors.greyDarker,
    borderTop: "transparent",
    borderRadius: "none",
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    color: colors.black,
    top: "auto",
    marginTop: "0",
  }),
  valueContainer: (styles) => ({...styles, padding: "6px"}),
  indicatorSeparator: (styles) => ({...styles, display: "none"}),
  indicatorsContainer: (styles) => ({
    ...styles,
  }),
  clearIndicator: (styles) => ({...styles, display: "none"}),
  placeholder: (styles) => ({
    ...styles,
    color: `${colors.black}`,
  }),
};

const Data = ({updateFormValues, availableBranches, productionBranch, previewOption, previewBranches}) => {

  const availableOptions = [
    {value: "all", label: "All"},
    {value: "none", label: "None"},
    {value: "select", label: "Only selected branches"},
  ];

  let availableBranchesData = [];
  let currentProductionBranch;

  availableBranches.map((branch) => {
    const branchData = {value: branch, label: branch};

    availableBranchesData.push(branchData);

    if (productionBranch === branch) {
      currentProductionBranch = branchData;
    }
  });

  let previewBranchesData = [];

  if (previewBranches && previewBranches.length > 0) {
    previewBranches.map((branch) => {
      const branchData = {value: branch, label: branch};

      previewBranchesData.push(branchData);
    });
  }

  return (
    <StyledContainer>
      <Row center>
        <OptionTitle>Live branch</OptionTitle>
        <OptionValues>
          <InputContainer>
            <SingleSelect options={availableBranchesData}
                          styles={singleSelectStyles}
                          defaultValue={currentProductionBranch}
                          onChange={e => updateFormValues("branch", e.value)}/>
          </InputContainer>
        </OptionValues>
      </Row>
      <Row>
        <OptionTitle>Preview branches</OptionTitle>
        <OptionValues>
          <RadioContainer>
            {availableOptions.map((option, index) => {
              return (
                <Radio key={index}>
                  <input
                    type="radio"
                    value={option.value}
                    checked={previewOption === option.value}
                    onChange={(e) => updateFormValues("previewOption", option.value)}
                  />
                  <span className="checkmark"/>
                  {option.label}
                </Radio>
              )
            })}
          </RadioContainer>
          {previewOption === "select" && (
            <CustomSelect
              closeMenuOnSelect={false}
              isMulti
              components={{DropdownIndicator, MultiValue}}
              options={availableBranchesData}
              value={previewBranchesData}
              styles={selectStyles}
              placeholder={"Branch names"}
              onChange={(e) => updateFormValues("previewBranches", e)}
            />
          )}
        </OptionValues>
      </Row>
    </StyledContainer>
  )
};

export default Data
