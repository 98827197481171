import api from "./core/api";

const BASE_PATH = '/settings';

async function getSettings() {
  return await api.get(BASE_PATH + '/');
}

async function update(productionBranch, previewOption, previewBranches) {
  return await api.put(BASE_PATH + '/', {
    productionBranch,
    previewOption,
    previewBranches
  });
}

const settings = {
  getSettings: getSettings,
  update: update
};

export default settings;
