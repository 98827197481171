import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";

const StyledButton = styled.button`
  ${fonts.montserratBold};
  font-size: 12px;
  line-height: 16px;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  outline: none;

  color: ${colors.white};
  background: ${colors.black};
  border: 1px solid ${colors.black};
  
  &:hover,
  &:active {
    background: ${colors.green};
    color: ${colors.white};
    border-color: ${colors.green};
  }
`;

const ButtonGreen = ({onClick, children}) => {
  return (
    <StyledButton onClick={onClick}>
      {children}
    </StyledButton>
  );
};

export default ButtonGreen
