import React, {useEffect, useState} from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import PrivateRoute from "../router/PrivateRoute"
import PageData from "../components/Core/PageData"
import Intro from "../components/Core/Intro/Intro"
import IntroTitle from "../components/Core/Intro/IntroTitle"
import PageTitle from "../components/Core/PageTitle"
import styled from "styled-components"
import fonts from "../styles/fonts"
import colors from "../styles/colors"
import Context from "../components/Settings/Context"
import settings from "../backend/settings";
import {navigate} from "gatsby";
import PremiumFeature from "../components/Settings/PremiumFeature";

const Content = styled.div`
  ${fonts.montserratRegular};
  color: ${colors.black};
  font-size: 14px;
  line-height: 23px;
  max-width: 450px;
  b {
    ${fonts.montserratMedium};
    color: ${colors.newBlack};
  }

  a {
    ${fonts.montserratBold};
    color: ${colors.green};
    text-decoration: none;
  }

`;

const SettingsPage = () => {
  const isCancelled = React.useRef(false);
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    updatePageData();

    return () => {
      isCancelled.current = true;
    };
  }, []);

  const updatePageData = () => {
    settings.getSettings()
      .then(({data}) => {
        if (isCancelled.current) {
          return;
        }

        if (data.hasRepo !== true) {
          navigate('/github/authenticate');

          return;
        }

        setPageData(data);
      })
      .catch(() => {
        // alert("Error! Please try again");
      });
  };

  const hasData = pageData !== null;

  return (
    <Layout>
      <SEO
        title="Settings"
        description="Deployment settings and configuration"
      />
      <PageData hasData={hasData}>
        <Intro>
          <IntroTitle>
            <PageTitle>Deployment configuration</PageTitle>
          </IntroTitle>
          <Content>
            <b>Configure the production branch and preview branches</b>
            <br/>
            Select your production branch and select to deploy all preview branches,
            none, or only a selection of preview branches.
          </Content>
        </Intro>
        {(hasData && pageData.hasConfigPlan) ? <Context {...pageData}/> : <PremiumFeature/>}
      </PageData>
    </Layout>
  )
};

function settingsPageWrapper(props) {
  return <PrivateRoute component={SettingsPage} {...props} />
}

export default settingsPageWrapper
