import React, {useState} from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import ButtonGrey from "../Core/ButtonGrey"
import Data from "./Data"
import settings from "../../backend/settings";
import {navigate} from "gatsby";

const StyledForm = styled.form`
  width: 100%;
  border: 1px solid ${colors.borderGrey};
  border-radius: 8px;
  background: ${colors.white};
`;

const TitleContainer = styled.div`
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.borderGrey};
  background: ${colors.greyLightPanel};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  h2 {
    ${fonts.montserratBold};
    font-size: 20px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Bottom = styled.div`
  padding: 20px 30px;
  display: flex;
  justify-content: flex-end;
`;

const Context = ({settingsData, availableBranches}) => {
  const [productionBranch, setProductionBranch] = useState(settingsData.liveBranch ? settingsData.liveBranch : settingsData.defaultBranch);
  const [previewOption, setPreviewOption] = useState(settingsData.previewOption ? settingsData.previewOption : "all");
  const [previewBranches, setPreviewBranches] = useState(settingsData.previewBranches);

  const updateFormValues = (type, value) => {
    if (type === "branch") {
      setProductionBranch(value)
    }

    if (type === "previewOption") {
      setPreviewOption(value)
    }

    if (type === "previewBranches") {
      let previewBranchesData = [];
      if (value && value.length > 0) {
        value.map((previewBranch) => {
          if (!previewBranch) {
            return;
          }

          previewBranchesData.push(previewBranch.value);
        });
      }

      setPreviewBranches(previewBranchesData)
    }
  };

  const updateSettings = (e) => {
    e.preventDefault();

    let previewBranchesData = [];

    if (previewBranches && previewBranches.length > 0) {
      previewBranches.map((branch) => {
        if (!branch) {
          return;
        }

        previewBranchesData.push(branch);
      });
    }

    settings.update(productionBranch, previewOption, previewBranchesData)
      .then(({data}) => {
        // todo
        window.location.reload();
      })
      .catch(() => {
        alert("Error! Please try again");
      });
  };

  return (
    <StyledForm onSubmit={(e) => updateSettings(e)}>
      <TitleContainer>
        <h2>Live and preview branches</h2>
      </TitleContainer>
      <div>
        <Data updateFormValues={updateFormValues}
              availableBranches={availableBranches}
              productionBranch={productionBranch}
              previewOption={previewOption}
              previewBranches={previewBranches} />
      </div>
      <Bottom>
        <ButtonGrey type="submit">Save</ButtonGrey>
      </Bottom>
    </StyledForm>
  )
};

export default Context
