import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"

const H1 = styled.h1`
  ${fonts.montserratBold};
  color: ${colors.newBlack};
  font-size: 30px;
  line-height: 37px;
`

const PageTitle = ({ children }) => {
  return <H1>{children}</H1>
}

export default PageTitle
